.img-container {
  height: 80vw;
  max-height: 480px;
  width: 80vw;
  max-width: 480px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.example-gif-small {
  height: 20vw;
  max-height: 240px;
}
.example-gif-medium {
  height: 50vw;
  max-height: 320px;
}
.example-gif-large {
  height: 80vw;
  max-height: 480px;
}
.speed-slider {
  margin-top: 2rem;
}
.size-slider {
  margin-top: 3rem;
}
.file-button {
  margin-top: 3rem;
}
.uploading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}