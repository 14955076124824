.img-container {
  height: 80vw;
  max-height: 480px;
  width: 80vw;
  max-width: 480px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
}
.generated-img {
  height: 80vw;
  max-height: 480px;
}
.download-container {
  margin-top: 2rem;
}
.download-container a {
  color: rgb(107, 211, 62);
}
.restart-container {
  margin-top: 2rem;
}
.restart-container a {
  color: rgb(190, 111, 229);
}